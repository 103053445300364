import React, { useCallback } from "react";

import { Link } from "react-router-dom";
import XLSX from "xlsx";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Dropzone from "@/components/Dropzone";
import Modal from "@/components/Modal";

import { useModalStore, useProfileStore } from "@/stores/hooks";

const Title = styled.h4`
  margin: 0;
`;

const Guide = styled.div`
  margin-bottom: 10px;

  a {
    color: #ea2845;
    text-decoration: underline;
  }
`;

const ButtonWrap = styled.div`
  margin-top: 10px;
  text-align: right;
`;

export default function ExcelUploadModal({
  title = "대량접수",
  downloadPath = "두발히어로_대량접수샘플",
  onSubmit,
  validation = false,
}) {
  const { closeModal } = useModalStore();

  const { user } = useProfileStore().state;

  const handleUploadBulkFile = async (binary) => {
    const workbook = XLSX.read(binary, {
      type: "binary",
    });

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
    try {
      if (worksheetArr.length > 0) {
        const columns = Object.keys(worksheetArr[0]);

        let mustColumns = [];

        if (validation) {
          if (
            !columns.find(
              (e) => e === user.spotAddressExcelSchemeObj?.customerName,
            )
          ) {
            mustColumns.push(user.spotAddressExcelSchemeObj.customerName);
          }
          if (
            !columns.find(
              (e) => e === user.spotAddressExcelSchemeObj?.customerMobile,
            ) &&
            !columns.find(
              (e) => e === user.spotAddressExcelSchemeObj?.customerMobile2,
            )
          ) {
            mustColumns.push(user.spotAddressExcelSchemeObj.customerMobile);
          }
          if (
            !columns.find(
              (e) => e === user.spotAddressExcelSchemeObj?.customerAddress,
            )
          ) {
            mustColumns.push(user.spotAddressExcelSchemeObj.customerAddress);
          }

          if (mustColumns.length > 0) {
            window.alert(
              `엑셀파일 1행에 필수항목이 누락되었습니다. 누락 항목 수정 후 재접수해주십시오. ${mustColumns
                .map((e) => `[${e}]`)
                .join(" ")}`,
            );
          } else {
            onSubmit(worksheetArr);
          }
        } else {
          onSubmit(worksheetArr);
        }
      }
    } catch (e) {
      // console.log(e)
    }

    closeModal();
  };

  const dropzoneProps = {
    accept: ".xls, .xlsx",
    onUpload: handleUploadBulkFile,
  };

  return (
    <Modal>
      <Modal.Header closeButton={false}>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <Guide>
          * 주소정보는&nbsp;
          <Link
            target="_blank"
            to={{ pathname: "http://postcode.map.daum.net/guide" }}
          >
            Daum 주소
          </Link>
          를 이용해서 작성해주십시오.
          {downloadPath !== "NONE" && (
            <>
              <br />* {title}용 엑셀파일 다운로드(
              <Link
                target="_blank"
                to={{
                  pathname: `https://doobalhero-files.s3.ap-northeast-2.amazonaws.com/${downloadPath}.xlsx`,
                }}
              >
                여기를 클릭하시면 다운로드 됩니다.
              </Link>
              )
              <br />* 첨부된 엑셀파일에서 접수내용만 수정해서 업로드해주셔야
              정상 등록됩니다.
            </>
          )}
          <br />
          * 주소정보가 맞지 않는 건은 오류 처리되어 등록되지 않습니다. (단건
          등록을 이용해주세요.)
          <br />* {title} 파일 등록 중에 창을 닫는 경우 오류가 날 수 있습니다.
          이용 시 주의 부탁드립니다.
          <br />
        </Guide>
        <Dropzone {...dropzoneProps} />
        <ButtonWrap>
          <Button
            onClick={closeModal}
            type="button"
            variant="outline-secondary"
            width="160px"
          >
            닫기
          </Button>
        </ButtonWrap>
      </Modal.Body>
    </Modal>
  );
}
